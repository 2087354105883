// extracted by mini-css-extract-plugin
export var ball = "aboutMe-module--ball--uS6o1";
export var bubble = "aboutMe-module--bubble--PYMn6";
export var bubbleAnim = "aboutMe-module--bubble-anim--QtvP5";
export var container = "aboutMe-module--container--pGv8x";
export var content = "aboutMe-module--content--AVwoh";
export var contentRight = "aboutMe-module--contentRight--F4NoB";
export var left = "aboutMe-module--left--XkMvi";
export var plain = "aboutMe-module--plain--lPa6O";
export var right = "aboutMe-module--right--QeA8V";
export var shadow = "aboutMe-module--shadow--YSjcB";
export var stage = "aboutMe-module--stage--rq3ya";
export var stageLeft = "aboutMe-module--stageLeft--lDSit";
export var timeline = "aboutMe-module--timeline--EhySw";